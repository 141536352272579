<template>
  <div>
    <div class="divider-full"></div>
    <div class="container-fluid">
      <div class="container-standar text-center">
        <span
          class="font-30 text-primary font-weight-7"
        >DETAIL DATA KEPENDUDUKAN PROVINSI PAPUA BARAT</span>
      </div>
      <div class="container-standar mt-10 mb-10 pb-10 card shadow ">
        <div class="row">
          <div class="col-lg-4 d-inline-flex mt-10 justify-center">
            <radial-chart></radial-chart>
          </div>
          <div class="col-lg-8 d-inline-flex mt-10">
            <div class="row">
              <div class="col-lg-12 shadow bg-white p-3 border-radius-5 flex mb-20">
                <span
                  class="font-20 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                >Total Jiwa</span>
                <span
                  class="font-20 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                >400 Orang</span>
              </div>
              <div class="col-lg-12 shadow bg-white p-3 border-radius-5 flex mb-20">
                <span
                  class="font-20 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                >Total KK</span>
                <span
                  class="font-20 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                >400 Orang</span>
              </div>
              <div class="col-lg-12 shadow bg-white p-3 border-radius-5 flex mb-20">
                <span
                  class="font-20 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                >Laki Laki</span>
                <span
                  class="font-20 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                >400 Orang</span>
              </div>
              <div class="col-lg-12 shadow bg-white p-3 border-radius-5 flex mb-20">
                <span
                  class="font-20 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                >Perempuan</span>
                <span
                  class="font-20 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                >400 Orang</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-standar  mt-10 mb-10 ">
        <div class="row">
          <div class="col-lg-7   d-inline-flex    ">
              <div class=" w-100 pl-2 pr-2 pb-5 pt-5 card  shadow">
            <span
              class="font-24 text-primary text-center pl-4 margin-auto font-weight-6"
            >Pertumbuhan Penduduk</span>
            <growth-chart></growth-chart>
          </div>
          </div>
          <div class="col-lg-5  d-inline-flex">
              <div class="card w-100 shadow pl-2 pr-2 pb-5 pt-5 ">
                     <span
              class="font-24 text-primary text-center pl-4 mb-10 font-weight-6"
            >Penduduk Sesuai Usia</span>
                 <bar-detail></bar-detail>
              </div>
          </div>
          <div class="col-lg-6 inline-flex mt-6">
            <div class="card width-100 shadow pad-20 ">
                 <span
              class="font-24 text-primary text-center pl-4 mb-10 font-weight-6"
            >Tingkat Pendidikan Terakhir </span>
              <tingkat-pendidikan></tingkat-pendidikan>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RadialChart from "./components/radialChart";
import GrowthChart from "./components/growthChart";
import BarDetail from "./components/BarDetail";
import TingkatPendidikan from "./components/TingkatPendidikan";

export default {
  components: {
    RadialChart,
    GrowthChart,
    BarDetail,
    TingkatPendidikan
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  }
};
</script>