<template>
    <div>
         <vue-apex-charts type="radialBar" height="350" :options="radialBarChart.chartOptions" :series="radialBarChart.series"></vue-apex-charts>
    </div>
</template>
 <script>
 import VueApexCharts from "vue-apexcharts";
 export default {
      components:{
     VueApexCharts
   },
       data() {
    return {
   
      radialBarChart: {
        series: [44, 83],
        chartOptions: {
          colors: ['#FF5306', '#1490F7'],
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: '15px',
                },
                value: {
                  fontSize: '16px',
                },
                total: {
                  show: true,
                  label: 'Total Penduduk',
                  formatter: function() {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return 5000
                  }
                }
              }
            }
          },
          labels: ['Laki-Laki', 'Perempuan'],
        }
      }
    }
  }
 }
 </script>